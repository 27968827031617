export default {
    MyBrand: { name: 'contiki', displayName: 'Contiki', fullName: 'Contiki', mtpName: 'MyContiki' },
    Accordion: { collapseAll: 'Collapse all', expandAll: 'Expand all' },
    AddMyBooking: {
        Card: { addButton: 'Add My Booking', header: 'Add your booked trip' },
        Form: {
            footer: 'Note: Your booking will only be available 24 hours after it has been confirmed.',
            header: '<p>To register and personalise your upcoming trip, add your booking to your MyContiki.</p><p><strong>Please enter your name exactly as it was given to MyContiki to make your booking. This is usually your name as it appears on your passport.</strong></p>',
            Fieldset: {
                title: 'Add My Booking Fields',
                fields: {
                    firstName: { placeholder: 'First Name', title: 'First Name' },
                    lastName: { placeholder: 'Last Name', title: 'Last Name' },
                    email: { placeholder: 'Email Address', title: 'Email Address' },
                    reference: { placeholder: 'Booking Reference', title: 'Booking Reference (eg. A123456)' },
                },
            },
        },
        AddMyBookingErrorMessages: {
            errorMessages: {
                ensureDetailsMatch:
                    "<p><ul class='AddMyBooking__errorMessagelist'><li>Please ensure your details match what was on your booking</li><li>Your booking has been confirmed</li><li>It has been 24 Hours since the booking was made</li></ul></p>",
            },
        },
    },
    App: { months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] },
    BookingsList: {
        bookingNameLabel: 'Booking Ref CH: ',
        pastBookingsHeader: 'Your past trips',
        upcomingBookingsHeader: 'Your upcoming trips',
        upcomingBookingsSubHeader:
            'All of your upcoming trips should be listed below, if a booking is missing click “Find my booking” to find and link it to your MyContiki.',
        viewButtonLabel: 'View Details',
        noBookingsFound: 'No Bookings Found! Bookings will appear 24 hours after booking has been confirmed.',
    },
    ContactUsButton: { text: 'Contact Us' },
    CallUsButton: {
        defaultPhoneNumber: '01234567890',
        sellingCompanyToPhoneNumberMapping: {
            CHSYDS: '1300 266 845',
            CHCANS: '866 224 0267',
            CHEUOS: '089 544 164 75',
            CHAKLS: '0508 266 8454',
            CHSINS: '6337 8166',
            CHJBGS: '011 280 8481 ',
            CHUSAS: '1 866 266 8454',
            CHUKLS: '0808 281 1120',
        },
    },
    Countdown: { day: { plural: 'Days', singular: 'Day' }, over: 'We Miss You', toGo: 'To Go', trip: 'Trip' },
    EdocsCard: {
        descriptions: [
            'Your travel docs will be available 7 to 10 business days prior to your trip departure date. Once they’re ready, you’ll be able to access all of your useful travel info here, and even download the docs to your device so you can view them anytime, even when offline. Easy.',
        ],
        dropDownLabel: 'Select a traveller',
        heading: 'Travel Documents',
        errorMessages: {
            regIncomplete: 'Please complete registration for $guestName$, we will need this information to be able to provide travel documents.',
            prefIncomplete:
                'Please complete special requirements & personalisation for $guestName$, we will need this information to be able to provide travel documents.',
            regAndPrefIncomplete:
                'Please complete special requirements & personalisation for $guestName$, we will need this information to be able to provide travel documents.',
            docsNotReady:
                'You have completed registration and special requirements & personalisation for $guestName$, travel documents will be available 7 to 10 business days prior to your departure.',
            docsNotFound: 'Your documents are not currently available, please check back later.',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Special requirements & personalisation',
                description: { incomplete: 'to customise your travel preferences', complete: 'to customise your travel preferences' },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register your details',
                description: {
                    incomplete: 'to ensure your trip runs smoothly and we can provide your travel documents',
                    complete: 'to ensure your trip runs smoothly and we can provide your travel documents',
                },
            },
        },
    },
    TravelExtras: {
        benefitsText:
            'For your benefit and clarity, the total airfare shown includes airline imposed fuel surcharges and government taxes and fees. If you need more clarification, please see our <a href="https://www.contiki.com/ca/en/resources/booking-conditions" target="_blank">Terms and Conditions</a>',
    },
    Gratuity: {
        title: 'Prepaid Gratuities',
        desc: 'Pay Service in advance',
        add: 'ADD',
        remove: 'REMOVE',
        paxText: 'Guests',
        addGratuity: 'Add Prepaid Gratuities',
        helpText: 'These include gratuities for your Trip Manager and Driver',
        passText: 'Guests:',
        gratuityDesc: "Don't miss out on showing your appreciation for exceptional service, add prepaid gratuities to your trip today.",
    },
    EdocsSubscriptionBox: {
        header: 'Your Travel Documents',
        paperDocumentsChoice: 'I want to travel with paper documents & not plant a tree',
        paperLessDocumentsChoice: 'I want to travel paper free & plant a tree',
        buttons: { viewDocuments: 'View Edocuments', choosePaperless: 'Choose Paperless' },
        isDocumentAndPaperLess: { heading: 'Congratulations! You have planted a tree.' },
        isDocumentWithPaper: { heading: 'Oh no! You missed out on planting a tree.' },
    },
    EdocsList: {
        documentDescriptions: {
            allDocument: 'You can download all of your documents for this traveller in a single click.',
            Cologne_Train_Transfer:
                'Directions for meeting your transfer at the Cologne Train Station. Includes meeting location, train station map, and contact information.',
            Cruise_Companion:
                'Guidelines to help you get ready for your upcoming journey. It includes, valuable tips on what to pack, your flights and transfers as well as on-trip information.',
            Cruise_Ticket: 'Your cruise eTicket, booking details and important terms and conditions.',
            Custom_Planning_Voucher: 'Contact information for private transfers and hotels.',
            Docking_Change_Notification: 'Important notice containing the updated docking location address, map, and contact information.',
            Flight_Itinerary: 'Contains your flight times, departure and arrival airports as well as terminal details for you to travel seamlessly.',
            Hotel_List:
                "Where you'll be staying on your trip. It's a good idea to share this with friends and family before you leave in case you need to be contacted or the unlikely event of an emergency.",
            Important_Transfer_Notice: 'Important notice that you have not taken advantage of our complete transfer options.',
            Schedule_At_A_Glance: 'An overview of your daily cruise itinerary.',
            Tour_Itinerary:
                'Discover your day by day trip breakdown, where to meet your group on Day 1, luggage guidelines, information about the optional experiences that can added to your trip and more.',
            Travel_Protection_Plan: 'Summary of coverages and contact information.',
            Venice_Port_Map: 'Map of the Venice port containing Uniworld Docking locations.',
            Vouchers_For_Information: 'For the best experience please ensure you read over these before your journey.',
            Vouchers_For_Printing:
                "Your pre-booked hotel and transfer vouchers. You'll need to print these out and bring with you to show the hotels and transfer partners.",
            Whats_Next: 'Where will you be going next? Let us help you plan your next experience with My Wishlist: start your wish list, get quotes and more…',
        },
        downloadAllBtn: 'Download All',
        downloadBtn: 'Download',
        voucherPrintMsg: 'This document must be printed',
    },
    WarningModal: {
        submit: 'Submit',
        next: 'Next',
        message: 'If you move away from this page your changes will be lost, please click $buttonName$ to save your changes.',
        buttons: { continue: 'Continue', back: 'Go back' },
    },
    Faq: {
        header: 'Frequently Asked Questions',
        description:
            "Please read the pre-travel info below. Not only will it help you make informed decisions as you travel, it'll also make your trip as effortless and enjoyable as possible, which is just the way we like it.",
        footer: { header: 'Still have questions?', description: 'Get in touch or speak to one of our experts on the phone.' },
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            {
                answer: '<p>There is indeed! And it’s super simple too – just sign in to My Contiki to get access to a group chat 30 days before your departure. You’ll be able to chat to all the travellers in your group (providing they’ve signed up too). Plus, your Trip Manager will also introduce themselves via a Welcome Email. So, keep an eye out!</p>',
                id: 'connect',
                question: 'Is there a way to connect with the other people on my trip before the trip starts?',
            },
            {
                answer: "<p>You’ll find all the info about your trip in the e-Documents in your MyContiki account about 7-10 business days before your departure (once you've registered and filled out your details). Your Trip Manager will also get in touch with you via email to introduce themselves and reconfirm your meeting point and time.</p>",
                id: 'meet',
                question: 'Where do I meet on Day 1?',
            },
            {
                answer: '<p>Cosy, comfy and casual – that’s the kinda vibe we recommend on a day-to-day basis. Some trips do include visits to bars, clubs or more formal evening activities, in which case packing a few more evening appropriate pieces is a good call. Jetting off to a sunny location? Don’t forget to pack swimwear (you’ll most likely have a beach day or a hotel pool).</p><p>If you’re travelling to areas where the culture and religion is different to your own, it’s important to be respectful of this and cover up, in particular if you are going to churches, temples, or places of respect.</p>',
                id: 'code',
                question: "What's the dress code on trip?",
            },
            {
                answer: '<p>As a rule of thumb, we recommend that you check with the local accommodation whether it’s safe to drink the tap water where you’re staying. In Western Europe, Scandinavia, the USA, Canada, Australia and New Zealand, it’s usually fine to drink the water from a fresh-supply tap. For other destinations, your Trip Manager will let you know if it’s okay.</p><p>At Contiki, we’re all about caring for our planet. So, we recommend that you pack a reusable water bottle for your trip (goodbye single-use plastics!). We’ll make multiple rest stops to allow you time to refill your bottle.</p>',
                id: 'drink',
                question: 'Can I drink the tap water in the countries I visit?',
            },
            {
                answer: "<p>Wanna take your trip to a whole new level? Say hello to Free Time Add-Ons! We’ve hand-picked travel experiences around activities we know you love. Think: food, adventure, exploration, history, culture, fashion, music - you name it.</p><p>You can get a flavour of what's in store for you in detailed <a href='/itinerary'>Trip Itinerary</a>.Your Trip Manager will also share all the options with you on the first day of your trip. Best part? You won’t have to book or prepay. Just let your Trip Manager know on the trip, make your payment (by cash or card, depending on where you’re travelling – more info in your trip document) and you’re all set!</p>",
                id: 'optional',
                question: 'What are Free Time Add-Ons and when can I book them?',
            },
            {
                answer: '<p>This one depends on the destination and trip you’re travelling on.</p><p>For our European, North American, Australian and New Zealand trips, luggage is restricted to one decent-sized, non-expanding suitcase, approximately 29" × 20" × 10" (73cm x 50cm x 25cm) with a maximum weight of 20 kilos (44lbs.). Plus, one small hand/day bag that can go on the coach with you. Metal frame backpacks are not advised as their bulky shape makes packing the coach tricky, plus they are more likely to damage other luggage.</p><p>On some of our trips, it is necessary to use an overnight bag for the occasional nights when space is restricted, for example if you are camping or on a Sailboat.</p><p>For our Africa trips, the baggage allowance changes according to every region. In Southern Africa, the maximum suitcase size is 73cm x 50cm x 25cm (29” x 20” x 10”) & weighing 23kg (50lbs). If you’re travelling to East Africa, the maximum bag size is 65 x 46cm with a weight of 15kg (33lbs). Plus, one day bag. (Please remember that only soft-sided bags are permitted!).</p><p>For safari trips and for trips where there is an overnight hiking or camping element (like Machu Picchu), you will also need a day bag (small backpack) to take with you. So it is a good idea to bring an overnight bag, or hand luggage that is big enough to double up as an overnighter when needed.</p><p>For Latin America, Australia, Asia & other regions which have internal flights included in the itinerary, luggage weight restrictions must be within the restriction of the given airlines you’re travelling on. So, please make sure that you bring luggage that’s within these measurements & weight restrictions to avoid excess baggage fees that you’ll have to cover at your own cost.</p>',
                id: 'luggage',
                question: 'Are there any luggage restrictions?',
            },
            {
                answer: '<p>Tipping very much depends on where in the world you are travelling. It’s generally worth reading up on the tipping culture of the region you are travelling to prior to your trip, or just ask your Trip Manager what the general etiquette is once on trip.</p><p>If you’re heading to America, tipping is a big part of the culture and is expected when paying your dinner bill, buying drinks at a bar, and even getting a taxi. But in Japan, tipping would be seen as impolite. In most parts of Europe, leaving a tip of around 5 - 10% of the total bill is the standard. Make sure you check the bill first though! A tip may have already been included in the total cost.</p><p>In terms of tipping Contiki staff and Local Guides, this is entirely up to you and is not compulsory. If you think a Trip Manager, Driver or Local Guide has done an awesome job, you’re welcome to tip them at your discretion and no one else’s. Tip what you think is right only.</p>',
                id: 'tipping',
                question: 'What about tipping?',
            },
            {
                answer: '<p>Visas are super important and are essential for entering certain countries. It’s up to you to obtain all necessary visas prior to the departure of your trip. We’ve got a handy <a target="_blank" href="https://www.contiki.com/resources/visas-guide">visa guide</a> for you to see whether you will need a visa for the destination you’re visiting.</p><p>Remember, visas can take up to 6 weeks to obtain, and a fee is normally charged – so don’t leave this one till the last minute! We recommend you apply for all visas through your travel agent or Embassy before your trip starts.</p>',
                id: 'visa',
                question: 'Will I need a Visa?',
            },
            {
                answer: "<p>Long days on the road and exploring can often mean a necessity to do laundry. The good news is lots of our accommodation offers laundry facilities. Mr. Stinky Socks who?</p><p>There will be a small fee for laundry services, as well as detergent (travellers tip - if you want to be super saving savvy, bring some laundry powder with you!). Or, if your accommodation doesn't have a laundry service and you’re really desperate, just ask your Trip Manager to help find a local launderette.</p>",
                id: 'laundry',
                question: 'Will I be able to get laundry done?',
            },
            {
                answer: "<p>We’ve got a bunch of travel app reccos for you:</p><p><a target='_blank' href='https://packr.app/'>Packr</a> and <a target='_blank' href='https://www.packpnt.com/'>PackPoint</a> will give you great packing tips based on forecasted weather.</p><p><a target='_blank' href='https://weather.com'>Weather Channel</a> or <a target='_blank' href='https://www.accuweather.com'>Accuweather</a> are both good weather apps to keep up to date with the local weather of the places you're headed.</p><p><a target='_blank' href='https://www.duolingo.com/'>Duolingo</a> will have you covering off the basics of a local language for just 5 minutes a day (Un Cafe por favor!)</p><p><a target='_blank' href='https://translate.google.com/'>Google Translate</a> is great for quick translations, especially labels and signs in languages with different characters.</p>",
                id: 'travelapps',
                question: 'What are the best travel apps to help me prepare for my trip?',
            },
            {
                answer: '<p>An instalment plan is a convenient payment option where you can divide the total cost of your trip into smaller automatic payments – made over time instead of paying the full amount upfront. In most cases, the total amount is split into equal instalments, and you can choose to pay monthly or 2-weekly.</p><p>Am I eligible?</p><p>To be eligible to enrol for a plan, the minimum plan length is two weeks prior to your ‘Pay in Full’ date, which gives you time to make one 2-weekly payment. If your trip starts less than 2 weeks after you make your booking, you won’t be able to set up an instalment plan. You will need to also have a valid credit/debit card that expires after the final day of your trip. Please note that some trips may be exempt if there’s a promotion running, or if you’ve purchased a last-minute deal.</p><p>If you are eligible for an Instalment plan, you will find detailed FAQs in the instalment plan section of MyContiki.</p>',
                id: 'instalmentPlanAmIeligible',
                question: 'What is an Instalment plan and am I eligible?',
            },
            {
                answer: "<p>No worries. Take a look at our extensive Traveller Guides <a target='_blank' href='https://www.contiki.com/resources/traveller-guides/'>here.</a></p>",
                id: 'moreQuestions',
                question: 'Still have more questions?',
            },
        ],
    },
    Footer: {
        copyright: 'Copyright $year$.',
        privacyPolicy: 'Privacy Policy',
        rightsReserved: 'All rights reserved.',
        termsAndCondition: 'Terms and Conditions',
    },
    Header: {
        CallUsButton: { suffix: 'or call your travel agent' },
        labelBrandSite: 'Back to Site',
        labelLogo: 'Home',
        labelLogoutMD: 'Log out of account',
        labelLogoutSM: 'Log Out',
        viewBasket: 'View Basket',
    },
    Chatter: {
        flagText: 'Report',
        flagSuccessText: 'Message has been successfully reported',
        flagErrorText: 'Error while reporting: Either this has been already reported or there is issue with network connection ...',
    },
    HelpBox: {
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            { id: 'connect', question: 'Is there a way to connect with the other people on my trip before the trip starts?' },
            { id: 'meet', question: 'Where do I meet on Day 1?' },
            { id: 'luggage', question: 'Are there any luggage restrictions?' },
            { id: 'allQuestions', question: "View all FAQ's" },
        ],
        header: 'Need some help?',
    },
    Hero: {
        greeting: { guest: 'Welcome to', member: 'Welcome to My Contiki', prefix: 'Hi' },
        alertMessage: '',
        healthAlertMessage:
            "<strong>Travel with confidence</strong> - High Hygiene standards and your health are our priority. <a href='https://www.contiki.com/uk/en/resources/travel-health' target='_blank'>Learn more</a>",
    },
    HomeButton: { buttonLabel: 'Home' },
    HotelMeals: {
        hotelTitle: 'Overnight',
        mealTitle: 'Meals inc.',
        helpText: 'All hotels have been carefully selected to enhance your trip, but please note they are subject to change any time.',
    },
    Itinerary: { accordionHeading: 'Select an Itinerary Day', dayTitle: 'Day' },
    ItineraryCard: { header: 'Trip Details', mapTitle: 'Trip Map', viewTripButton: 'View Trip' },
    Loader: { message: 'Loading...' },
    LoginForm: { emailAddress: 'Email Address', password: 'Password' },
    SignUpCard: {
        login: 'Login',
        signup: 'Sign Up',
        loginHeader: 'Welcome to',
        signUpHeader: 'Welcome to',
        description: 'My Contiki is your personalised online trip hub to manage all of your travel details and documents.',
        firstname: 'First Name',
        lastname: 'Last Name',
        emailAddress: 'Email Address',
        passWord: 'Password',
    },
    MmbRegistration: { registerText: 'Want to register for your trip without creating an account? ' },
    Modal: { closeBtn: 'close' },
    Navigation: {
        navigationList: {
            Edocs: 'Download your documents',
            Faq: 'Help',
            Itinerary: 'Trip Itinerary',
            TripPersonalisation: 'Special requirements & personalisation',
            TripRegistration: 'Trip registration',
        },
    },
    NotFound: { message: 'Made a wrong turn? No problem. Visit our home page to access your My Contiki travel information.' },
    Optional: {
        heading: 'Free Time Add-Ons',
        helpText:
            'These are the optional experiences that have been hand picked to help take your trip to the next level. Your Trip Manager will arrange booking/s and payment directly with you on your trip. Please note that all experiences are indicative only and may be withdrawn or changed at any time.',
        priceForAdult: 'Price:',
        priceForChild: '',
        viewDetail: 'View Details',
    },
    TripPersonalisationWizard: {
        Buttons: { labels: { back: 'Back', complete: 'Next traveller', next: 'Next', submit: 'Submit', skip: 'Complete Later' } },
        Header: { multiPax: 'Special requirements & personalisation for $guestName$', singlePax: 'Special requirements & personalisation ' },
        requiredHint: '* Required',
        errorMessages: { unableToSave: 'Unable to edit your information at this time, please try again later.', beddingPreference: '' },
        Steps: {
            PersonaliseDetails: {
                beddingOptions: { heading: '', description: '', Fieldset: { description: '', fields: { doubleBed: '', twinBed: '' }, title: '' } },
                addtionalInformation: {
                    heading: '',
                    Fieldset: {
                        personalRequest: {
                            description: 'Please advise any medical, dietary, allergy or other special requirements you have.',
                            fields: { comment: { placeholder: '', title: 'Add comment' } },
                            title: 'Personal requests',
                        },
                        travelPassion: {
                            description: 'What are you most looking forward to about your trip?',
                            fields: { comment: { placeholder: '', title: 'Add comment' } },
                            title: 'Your travel passion',
                        },
                    },
                },
            },
            PersonalisationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed personalisation for $guestName$, but still need to complete registration.',
                    },
                    incomplete: {
                        both: 'You have not completed special requirements & personalisation section for $guestName$.',
                        self: 'You have not completed special requirements & personalisation section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to enjoy the trip of a life time!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    registration: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Register your details',
                        description: {
                            incomplete: 'to ensure your trip runs smoothly and we can assist in the unlikely event of an incident',
                            complete: 'to ensure your trip runs smoothly and we can assist in the unlikely event of an incident',
                        },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Documents will be ready 7 to 10 business days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
        },
        SubHeader:
            'Let us know about any special requirements such as dietary requirements, allergies or medical requirements so we can share this information with your Trip Manager. Every effort will be made to accommodate your needs and any special requests.',
    },
    TripRegistrationWizard: {
        Buttons: {
            labels: {
                back: 'Back',
                complete: 'Next Traveller',
                edit: 'Make Changes',
                next: 'Next',
                submit: 'Submit',
                skip: 'Complete Later',
                view: 'View Documents',
            },
        },
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            validationErrors: 'You must provide required details to complete registration.',
            travelInsurance: 'You must select an option to complete registration.',
        },
        Header: { multiPax: 'Trip Registration for $guestName$', singlePax: 'Trip Registration' },
        requiredHint: '* Required',
        Steps: {
            ContactDetails: {
                heading: 'On Trip Contact Details',
                description: 'In the unlikely event of an incident, we will use these details to contact you.',
                Fieldset: {
                    fields: {
                        emailAddress: { placeholder: 'Email address', title: 'Email address' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Select', number: 'Contact number' }, title: 'Contact number' },
                    },
                    title: 'Trip Contact Details Fields',
                },
            },
            EmergencyContact: {
                heading: 'Emergency Contact',
                description: 'In the unlikely event of an incident, we will contact the person nominated, this person must not be on the trip with you.',
                Fieldset: {
                    fields: {
                        name: { placeholder: 'Name', title: 'Name' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Pick', number: 'Contact number' }, title: 'Contact number' },
                        relationship: { placeholder: 'Relationship', title: 'Relationship' },
                    },
                    title: 'Emergency Contact Fields',
                },
            },
            PassportDetails: {
                heading: 'Passport Details',
                description:
                    '<p>Please provide details so we can ensure a seamless trip from hotel check-ins to boarder security checks and more. Incomplete or inaccurate details may result in services being affected or additional charges.</p><p> Many countries require passports to be valid for a minimum of 6 months after travel. Please check with local consular services for passport validity and visa requirements for your tour.</p>',
                Callout: {
                    messageForCheckPassportExpiry:
                        'Please be advised that many countries require that passports be valid for a minimum of 6 months after the completion of your trip. Please check the local consulate for passport validity requirements for your trip.',
                },
                Fieldset: {
                    fields: {
                        countryOfIssue: { placeholder: 'Country of Issue', title: 'Country of Issue' },
                        dateOfBirth: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of Birth *' },
                        expiryDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Expiry Date *' },
                        issueDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of issue *' },
                        nationality: { placeholder: 'Nationality', title: 'Nationality' },
                        number: { placeholder: 'Passport number', title: 'Passport number *' },
                    },
                    title: 'Passport Details Fields',
                },
            },
            RegistrationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed registration for $guestName$, but still need to complete special requirements and personalisation.',
                    },
                    incomplete: {
                        both: 'You have not completed registration section for $guestName$.',
                        self: 'You have not completed registration section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to enjoy the trip of a life time!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    personalisation: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Personalise your trip',
                        description: {
                            incomplete: 'to alert us to any special requirements and to customise your travel preferences',
                            complete: 'to alert us to any special requirements and to customise your travel preferences',
                        },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Documents will be ready 7 to 10 business days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
            TravelInsurance: {
                heading: 'Travel Insurance',
                description: 'To ensure your peace of mind while travelling, it is essential that you have travel insurance before you depart.',
                Callout: {
                    messageForTravelProtection: 'You have purchased $name$ with us, however this is not Travel Insurance.',
                    messageForInsuranceWaiver:
                        "<p class='TravelInsurance__descriptionText'>$brandName$'s booking conditions require you to obtain adequate travel insurance. Such insurance should ensure that you are fully covered against unexpected cancellations charges, medical expenses arising abroad, loss of luggage or money, adventure activities, and personal liability claims. Acceptance of this condition in no way limits the legal obligations to you where we, $brandName$, the provider of your holiday are at fault. Our obligations are set out in your holiday contract (see the Terms & Conditions in the relevant selling region’s brochure for full details).</p><p class='TravelInsurance__descriptionText'>I have declined to take out travel insurance cover prior to joining my trip. I hereby undertake to:</p><p class='TravelInsurance__descriptionText'><ol><li>Arrange adequate travel insurance prior to joining my trip and update My$brandName$ or my Trip Manager on Day 1 OR </li><li>Not to hold Contiki responsible for any cost incurred by my failure to take out adequate insurance, and to indemnify Contiki for any costs incurred by them due to my failure to take adequate insurance cover.</li></ol></p><p>I understand that my acceptance of this condition of travel does not affect my statutory rights.</p>",
                    CHUSAS: "<p class='TravelInsurance__descriptionText'>All guests are required to have travel insurance in order to travel with $brandName$. You are not required to purchase travel insurance from $brandName$ and may purchase travel insurance from any provider that includes the following coverage: Trip Interruption, Trip Delay, Medical Expenses, Quarantine Expenses and Emergency Evacuation/Repatriation. The insurance must cover medical costs and a medically imposed quarantine if the insured, or someone the insured has come into contact with, tests positive for COVID-19. Please check <a href='$nonMandatoryCalloutLink$' target='_blank'>here</a> for additional details and minimum coverage requirements. If you do not currently have travel insurance, please contact $brandName$ or your travel advisor as soon as possible to discuss your options.</p><p class='TravelInsurance__descriptionText'>Should you choose to travel on $brandName$ without the required travel insurance, please check the box below to acknowledge your agreement to take full responsibility for all expenses (trip interruption, trip delay, medical expenses, quarantine expenses and emergency evacuation/repatriation) that may arise while traveling with $brandName$. Further, you agree to indemnify $brandName$ against all third-party claims, actions, damages and remedies which may be brought against $brandName$ as a result of your participation in the $brandName$ trip.",
                    messageForCallAgent:
                        '<p>It is strongly recommended that you take out comprehensive insurance cover for cancellation, personal injury, death, medical expenses, repatriation expenses, evacuation expenses, personal liability & loss of personal property before you travel on a Contiki holiday. Please check that the insurance covers all of the activities that you are going to be participating in as some policies exclude certain adventure activities. Your insurance policy should be arranged at the time of payment of the deposit & will, in certain circumstances, cover you against loss of deposit or cancellation fees from the date of confirmation of your booking, as shown in the insurance policy.</p><p>Contiki cannot be held responsible for your failure to take out appropriate insurance. Should you choose to travel on a Contiki holiday without appropriate insurance, you also agree to indemnify us against all third-party claims, actions, damages & remedies which may be brought against us in respect of your participation in the holiday.</p>',
                },
                Fieldset: {
                    insurance: { fields: { insuranceStatusNo: "No I don't", insuranceStatusYes: 'Yes I do' }, title: 'Do you have travel insurance?' },
                    policy: {
                        fields: {
                            companyName: { placeholder: 'Company name', title: 'Company name' },
                            phoneNumber: {
                                placeholder: { internationalCallingCode: 'Select', number: 'Company contact number' },
                                title: 'Company contact number',
                            },
                            policyNumber: { placeholder: 'Policy number', title: 'Policy number' },
                        },
                        title: 'Policy Details',
                    },
                },
                travelProtectionInfo: { heading: 'Travel protection for $guestName$', description: 'Valid dates: $startDate$ - $endDate$' },
            },
        },
        SubHeader: 'For your peace of mind, we require the following information to ensure everything runs smoothly while you are on your trip.',
    },
    TripSchedule: { tripEndHeader: 'End', tripStartHeader: 'Start' },
    TripRegistrationCard: {
        alert: { singleItem: '1 item requires your attention', multipleItems: '2 items require your attention' },
        header: 'Trip Registration',
        subHeader: 'Your important information',
        guestTraveller: {
            header: 'Your Traveller information',
            description: '($completedGuests$ of $totalGuests$ completed)',
            detailNotComplete: 'Please complete the trip registration and special requirements and personalisation for all travellers on your booking.',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Personalise your trip',
                description: {
                    incomplete: 'to let us know any special requirements and to customise your travel preferences',
                    complete: 'to let us know any special requirements and to customise your travel preferences',
                },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register your details',
                description: { incomplete: 'to ensure your trip runs smoothly', complete: 'to ensure your trip runs smoothly' },
            },
        },
    },
    MultiGuest: {
        header: 'Travellers',
        titleHeading: 'Your Information',
        edit: 'Edit',
        TripPersonalisation: {
            subHeading: '$completed$ / $total$ personalisations complete',
            progress: { incomplete: 'Personalisation Progress', complete: 'Personalisation Complete' },
        },
        TripRegistration: {
            subHeading: '$completed$ / $total$ registrations complete',
            progress: { incomplete: 'Registration Progress', complete: 'Registration Complete' },
        },
    },
    ValidationMessages: {
        minLength: { characters: 'Please enter minimum $length$ characters', numbers: 'Please enter minimum $length$ numbers' },
        maxLength: { characters: 'Please enter no more than $length$ characters', numbers: 'Please enter no more than $length$ numbers' },
        numberOnly: "Can't type in letters",
        alphaNumericOnly: 'Can not type in special characters',
        emailFormat: 'Please enter a valid email address',
        phoneFormat: 'Please enter a valid phone number',
        dateFormat: 'Invalid date',
        required: 'This field is required',
    },
    Sustainability: {
        Card: {
            header: 'JoinContiki',
            ourStoryTitle: '',
            ourStorySubTitle: 'SUSTAINABILITY AT CONTIKI',
            ourStoryText:
                '<p>It’s all part of how we MAKE TRAVEL MATTER®</p><p>Travel has the power to benefit more than just travellers. Here at Contiki, it’s our mission to MAKE TRAVEL MATTER® for people, wildlife and the wonderful planet we all share. </p>',
            whatWeDoTitle: '',
            whatWeDoSubTitle: '',
            whatWeDoText: '',
            travelPledgeTitle: '',
            travelPledgeSubTitle: 'OUR SUSTAINABILITY STRATEGY',
            travelPledgeText:
                '<p>The aim? Reach Net Zero Emissions by 2050, if not before. How to get there? By following our 4-point Climate Action plan: <ul><li>●	<strong>Measure</strong> the emissions that come from our trips & wider business </li><li>●	<strong>Reduce</strong> emissions with the help of our dedicated Carbon Fund </li><li>●	<strong>Restore</strong> natural areas by investing in nature-based solutions & conservation efforts </li><li>●	<strong>Evolve</strong> along with technology & support the transition to a low (or no) carbon future</li></ul></p><p>Our sustainability strategy, How We Tread Right, consists of 11 key goals, each anchored to the UN’s Global Goals for Sustainable Development. We measure ourselves against these goals each year to help us track our progress.</p>',
            pledgeSubTitle: 'WANNA MAKE AN IMPACT WHILE MAKING MEMORIES TOO?',
            pledgeText:
                '<p>Keen to support people, wildlife and the planet while you’re on the road? MAKE TRAVEL MATTER® Experiences are now available on 80% of our trips, chosen specially for their positive social or environmental impact. They’re also very educational (and very fun too.)</p>',
            brandPledgeInfoButtonText: 'Find Out More',
            infoTile: {
                planet: {
                    altText: 'heart icon',
                    heading: 'Planet',
                    description:
                        'With our Sustainability Strategy and ambitious carbon reduction goals, we’re working to foster a healthy planet for the travellers of tomorrow (and a long while after that!)',
                },
                people: {
                    altText: 'eye icon',
                    heading: 'People',
                    description:
                        'Helping local communities and cultures to thrive, and supporting the causes we care about across the world – from LGBTQIA+ rights to Indigenous storytelling and beyond.',
                },
                wildlife: {
                    altText: 'star icon',
                    heading: 'Wildlife',
                    description:
                        'With the help of our Animal Welfare Policy, we’re working to protect, rehabilitate, and promote ethical practices. 100% of our wildlife experiences are free from cruelty.',
                },
            },
        },
    },
}

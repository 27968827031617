/* eslint-disable no-nested-ternary */
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'qs'
import { Utils, BookingServices, SSO } from 'helpers'
import { toggleLoader, toggleErrorModal, updateSessionData } from 'redux/actions'
import * as defaultState from 'redux/defaultState'
import { AppConstant } from 'constants'

const {
    TravelExtras: { flightAlreadyExistMessage, versioningError, flightAddError, addProductErrorMessage, confirmFlightMessage, searchFlightErrorMessage },
} = Utils.getTranslations()

const dontShowOutageMessageApis = ['addbooking', 'stream-io-api', 'getinvoice', 'addflight']

const customMessageConfig = {
    addproducts: {
        showViewBooking: true,
        message: addProductErrorMessage,
        showPopupInVersioniningError: true,
        versioningMessage: versioningError,
    },
    searchflights: {
        showViewBooking: false,
        message: searchFlightErrorMessage,
    },
    updatebookingintropics: {
        message: flightAddError,
        showPopupInVersioniningError: true,
        versioningMessage: versioningError,
        showViewBooking: true,
    },
}

export class AxiosInterceptors extends PureComponent {
    static displayName = 'AxiosInterceptors'

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
        dispatchToggleLoader: PropTypes.func.isRequired,
        dispatchToggleErrorModal: PropTypes.func.isRequired,
        dispatchUpdateSessionData: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.xhrReq = Utils.ajax().interceptors.request
        this.xhrRes = Utils.ajax().interceptors.response
    }

    componentDidMount() {
        this.init()
        this.bindAxiosInterceptors()
    }

    componentDidUpdate() {
        this.unbindAxiosInterceptors()
        this.init()
        this.bindAxiosInterceptors()
    }

    componentWillUnmount() {
        this.unbindAxiosInterceptors()
    }

    init = () => {
        this.ajaxCallInProgress = this.ajaxCallInProgress || 0
        this.requestInterceptor = null
        this.responseInterceptor = null
    }

    unbindAxiosInterceptors = () => {
        const { dispatchToggleLoader } = this.props

        this.xhrReq.eject(this.requestInterceptor)
        this.xhrRes.eject(this.responseInterceptor)
        dispatchToggleLoader(false)
    }

    bindRequestConfig = config => {
        const { dispatchToggleLoader } = this.props
        const isChatApiCall = config.url && config.url.indexOf('https://chat-us') >= 0
        const isSSORegCall = config.url && config.url.indexOf('/ssoregistration') >= 0

        const { skipAuthHeaders = false } = config
        this.ajaxCallInProgress += 1
        if (!isChatApiCall && !config.skipLoader) {
            dispatchToggleLoader(true)
        }
        const inSecureLogin = sessionStorage.getItem('insecurelogin')
        if (inSecureLogin) {
            const authHeader = { authorization: process.env.INSECUREMODE }
            return Promise.resolve({
                ...config,
                baseURL: `${process.env.BACKEND}`,
                timeout: AppConstant.API_TIMEOUT,
                paramsSerializer: params =>
                    qs.stringify(params, {
                        strictNullHandling: true,
                        indices: true,
                        arrayFormat: 'indices',
                        encodeValuesOnly: true,
                    }),
                headers: {
                    ...config.headers,
                    ...authHeader,
                },
            })
        }
        return SSO.getToken().then(token => {
            const authHeader = token ? { authorization: `bearer ${token}` } : {}

            if (!isChatApiCall) {
                config = {
                    ...config,
                    baseURL: `${process.env.BACKEND}`,
                    timeout: AppConstant.API_TIMEOUT,
                    paramsSerializer: params =>
                        qs.stringify(params, {
                            strictNullHandling: true,
                            indices: true,
                            arrayFormat: 'indices',
                            encodeValuesOnly: true,
                        }),
                    headers: {
                        ...config.headers,
                        ...(skipAuthHeaders || isSSORegCall ? {} : authHeader),
                    },
                }
            }
            return Promise.resolve(config)
        })
    }

    bindRequestErrorConfig = error => {
        const { dispatchToggleErrorModal } = this.props
        Utils.consoleErr(error)
        const { request: { data } = {} } = error
        dispatchToggleErrorModal({ errorModalVisible: true })
        return Promise.reject(data || error)
    }

    bindResponseConfig = responseObj => {
        const { dispatchToggleLoader } = this.props
        this.ajaxCallInProgress -= 1
        if (!this.ajaxCallInProgress) {
            dispatchToggleLoader(false)
        }
        const isChatApiCall = responseObj && responseObj.config && responseObj.config.url && responseObj.config.url.indexOf('https://chat-us') >= 0
        if (isChatApiCall) {
            Utils.changeTextForChat(true, responseObj.config.url)
        }
        return responseObj
    }

    bindResponseErrorConfig = error => {
        const { dispatchToggleLoader, dispatchToggleErrorModal, activeBookingDetails } = this.props
        Utils.consoleErr(error)
        const { config, code = '', response: { data } = {}, message = 'Failed to process request' } = error
        const { skipErrorModal = false, url } = config
        const { fieldErrors, versionConflictError, confirmFlightError } = BookingServices.getErrors(data || {})
        const err = {}
        let isMarketingAPIError = false
        if (data && data.message === 'API_404ERROR') {
            isMarketingAPIError = true
        }
        let recordLockedError = false
        if (data && data.errors && data.errors.lockedError) {
            recordLockedError = true
        }
        this.ajaxCallInProgress -= 1

        if (!this.ajaxCallInProgress) {
            dispatchToggleLoader(false)
        }

        if (code === AppConstant.ERROR_CODES.ECONNABORTED) {
            err.message = message
            err.errorCode = code
            err.errorId = Utils.getUID()
            err.statusCode = 408
            err.status = 408
        }
        const { message: errorMessage, response = {} } = error
        const { status } = response
        const isChatApiCall = response && response.config && response.config.url && response.config.url.indexOf('https://chat-us') >= 0
        if (isChatApiCall) {
            Utils.changeTextForChat(false, response.config.url)
        }
        if (isChatApiCall) {
            return Promise.reject((err.status ? err : null) || data || error)
        }

        if (errorMessage === AppConstant.ERROR_CODES.AUTH_FAILED || status === 403 || status === 401) {
            const { dispatchUpdateSessionData } = this.props
            const { userData } = defaultState
            if (typeof SsoHelper !== 'undefined') {
                const ssoHelper = new SsoHelper()
                if (typeof ssoHelper.logout === 'function') {
                    ssoHelper.logout()
                }
            }
            dispatchUpdateSessionData(userData.authToken)
            Utils.consoleErr((err.status ? err : null) || data || error)
            return Promise.reject((err.status ? err : null) || data || error)
        }
        let customConfig = false
        Object.keys(customMessageConfig).forEach(rec => {
            if (url && url.includes(rec)) {
                customConfig = customMessageConfig[rec]
            }
        })
        // TODO:- Remove unnecessary checks
        if (
            !fieldErrors &&
            (!versionConflictError || (customConfig && customConfig.showPopupInVersioniningError)) &&
            !skipErrorModal &&
            url &&
            !dontShowOutageMessageApis.find(rec => url.includes(rec))
        ) {
            dispatchToggleErrorModal({
                errorModalVisible: true,
                showViewBooking: customConfig ? customConfig.showViewBooking : false,
                dontShowCloseButton: !!(versionConflictError && customConfig && customConfig.showPopupInVersioniningError),
                isLockedError: recordLockedError,
                isAPIError: isMarketingAPIError,
                customMessage: confirmFlightError
                    ? confirmFlightMessage
                    : customConfig
                    ? versionConflictError
                        ? customConfig.versioningMessage
                        : customConfig.message
                    : '',
            })
        }
        if (url && url.includes('addflight')) {
            let index = -1
            if (data.message && typeof data.message === 'string') {
                index = data.message.indexOf(':')
            }
            if (index >= 0) {
                const clientIds = data.message && data.message.substring(index + 1).split(',')
                let guestNames = ''
                clientIds.forEach((pax, i) => {
                    const rec = activeBookingDetails.allPassengers.find(e => Number(e.id) === Number(pax))
                    if (rec) {
                        if (i !== clientIds.length - 1) {
                            guestNames = `${guestNames + rec.firstName} ${rec.lastName},`
                        } else {
                            guestNames = `${guestNames + rec.firstName} ${rec.lastName}`
                        }
                    }
                })
                dispatchToggleErrorModal({
                    errorModalVisible: true,
                    showViewBooking: false,
                    isLockedError: recordLockedError,
                    isAPIError: isMarketingAPIError,
                    customMessage: flightAlreadyExistMessage.replace('placeholder', guestNames),
                })
            } else {
                dispatchToggleErrorModal({
                    errorModalVisible: true,
                    isLockedError: recordLockedError,
                    isAPIError: isMarketingAPIError,
                    customMessage: flightAddError,
                })
            }
        }
        return Promise.reject((err.status ? err : null) || data || error)
    }

    bindAxiosInterceptors = () => {
        this.requestInterceptor = this.xhrReq.use(this.bindRequestConfig, this.bindRequestErrorConfig)
        this.responseInterceptor = this.xhrRes.use(this.bindResponseConfig, this.bindResponseErrorConfig)
    }

    render() {
        const { children } = this.props
        return children
    }
}

const mapStateToProps = state => ({
    activeBookingDetails: state.activeBookingDetails,
})

export default connect(mapStateToProps, dispatch => ({
    dispatchToggleLoader: payload => dispatch(toggleLoader(payload)),
    dispatchToggleErrorModal: payload => dispatch(toggleErrorModal(payload)),
    dispatchUpdateSessionData: payload => dispatch(updateSessionData(payload)),
}))(AxiosInterceptors)
